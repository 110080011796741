import { StyledCard } from "./Styles/StyledComponents/StyledCards";
import { StyledForm } from "./Styles/StyledComponents/StyledForm";
import { StyledHeader } from "./Styles/StyledComponents/StyledHeader";
import { StyledMain } from "./Styles/StyledComponents/StyledMain";
import { StyledPromo } from "./Styles/StyledComponents/StyledPromo";
import { StyledSection } from "./Styles/StyledComponents/StyledSection";
import Footer from "./components/Footer";
import EuroIcon from "@mui/icons-material/Euro";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ApartmentIcon from "@mui/icons-material/Apartment";
import img1 from "./images/buildImage1.jpg";
import img2 from "./images/buildImage2.jpg";
import img3 from "./images/buildImage3.jpg";
import img4 from "./images/buildImage4.jpg";
import { ReactComponent as BrandIcon } from "./images/logo.svg";
import emailjs from "emailjs-com";
import { useRef, useState } from "react";

interface ToggleCardsState {
  [key: string]: boolean;
}

const Home = () => {
  const [isChecked, setIsChecked] = useState(false);

  const [toggleCards, setToggleCards] = useState<ToggleCardsState>({
    card1: false,
    card2: false,
    card3: false,
  });

  const form = useRef<HTMLFormElement>(null);
  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const sendEmail = () => {
    if (form.current) {
      emailjs.sendForm("service_ix4m3zi", "template_d1d2eev", form.current, "qgJ7Ycqdm2BsAqhH7").then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };
  const handleToggleCard = (cardName: string): void => {
    setToggleCards((prevState) => ({
      ...prevState,
      [cardName]: !prevState[cardName],
    }));
  };
  return (
    <>
      <StyledHeader>
        <div className="img1">
          <img src={img1} alt="constructions" />
        </div>
        <div className="img2">
          <img src={img2} alt="constructions" />
        </div>
        <div className="img3">
          <img src={img3} alt="constructions" />
        </div>
        <div className="img4">
          <img src={img4} alt="constructions" />
        </div>
      </StyledHeader>

      <StyledMain>
        <div className="logo_container">
        <div className="logo"><BrandIcon /></div>
        <h1><span className="h1-1">STAY AALBORG</span>
        <span className="h1-2">LOCAL WORKCREW ACCOMMODATION</span></h1>
        </div>
  
        <div className="card_container">
          {" "}
          <StyledCard customHeight={"550px"} toggleCard={toggleCards.card1}>
            <div className="icon">
              <EuroIcon />
            </div>
            <h2>Affordable apartment rentals from just €19/night per person!</h2>
            <div className="dashed_line" />
            <p>
              {" "}
              Are you planning a stay in Aalborg, Denmark, with a workgroup or a larger group? Look no further! We offer apartment rentals that cater
              specifically to the needs of your group. Whether your stay is short or long, we have the perfect solution for you. <br /> <br />
              Prices start at €19/night per person.
            </p>

            <button className="card_btn" onClick={() => handleToggleCard("card1")}>
              {toggleCards.card1 ? "Read Less" : "Read More"}
            </button>
          </StyledCard>
          <StyledCard customHeight={"650px"} toggleCard={toggleCards.card2}>
            <div className="icon">
              <ApartmentIcon />
            </div>
            <h2>Fully-Furnished apartments with modern amenities and parking options for up to 10 vehicles</h2>
            <div className="dashed_line" />
            <p>
              {" "}
              Our furnished apartments provide comfortable accommodation with basic amenities, including towels and bed linen for all guests. The apartments
              feature bedrooms, ranging from single occupancy to six-person rooms, depending on your requirements and budget. Each apartment is equipped with a
              toilet, shower/bath, kitchen, and a living area complete with Wi-Fi, TV, and Chromecast. Additionally, guests have access to laundry facilities,
              and private parking can be arranged for up to 10 vehicles.
            </p>

            <button className="card_btn" onClick={() => handleToggleCard("card2")}>
              {toggleCards.card2 ? "Read Less" : "Read More"}
            </button>
          </StyledCard>
          <StyledCard customHeight={"550px"} toggleCard={toggleCards.card3}>
            <div className="icon">
              <ThumbUpIcon />
            </div>
            <h2>Indulge in a comfortable and stress-free stay at our Aalborg apartments</h2>
            <div className="dashed_line" />
            <p>
              Convenience and flexibility are at the heart of our service. We understand the importance of a comfortable and hassle-free stay, especially when
              you're away from home for an extended period. Our apartments offer all the necessary comforts, ensuring that you can focus on your work or enjoy
              your time in Aalborg.
            </p>
            <button className="card_btn" onClick={() => handleToggleCard("card3")}>
              {toggleCards.card3 ? "Read Less" : "Read More"}
            </button>
          </StyledCard>
        </div>

        <StyledPromo>
          <p>
            "Our dedicated team is committed to delivering exceptional service and ensuring that your accommodation needs are met. We take pride in providing
            clean and well-maintained apartments that offer a comfortable and inviting atmosphere. Should you require any assistance or have specific requests,
            our staff is always available to help."
          </p>
        </StyledPromo>
        <StyledSection>
          <h2>Quick and easy booking</h2>
          <p>
            Booking your apartment rental in Aalborg is quick and easy. Contact us to check availability, discuss your group's requirements, and secure your
            reservation. Whether you're planning a business trip, a team-building retreat, or any other group event, we have the ideal accommodation solution
            for you.
          </p>
        </StyledSection>
        <StyledForm>
          <div>
            <form
              ref={form}
              onSubmit={(e) => {
                e.preventDefault();
                if (isChecked) {
                  sendEmail();
                  alert("Thank you for reaching out to us, we will contact you as soon as possible.");
                  window.location.reload();
                } else {
                  alert("Please accept that you have read the privacy policy");
                }
              }}
            >
              <input type="email" name="email" placeholder="Email" required />
              <input type="text" name="firstname" placeholder="Firstname" required />
              <input type="text" name="phone" placeholder="Phone Number" required />
              <textarea required name="message" placeholder="Tell us about your group and the deal you're looking for." />
              <div>
                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} name="check" />
                <label htmlFor="check">I have read and accept the privacy policy.</label>
              </div>

              <button>Contact</button>
            </form>
          </div>
          <div>
            <h2>Reach out to us</h2>
            <ul>
              <li>LWA Group</li>
              <li className="service">Servicing our clients since 2011</li>
              <li className="contact_info">
                <div className="whatsApp">
                  <WhatsAppIcon />
                </div>
                +45 31 600 800
              </li>
              <li>
                <div>
                  <EmailIcon />
                </div>
                Lwagroup@outlook.com
              </li>
            </ul>
          </div>
        </StyledForm>
      </StyledMain>
      <Footer />
    </>
  );
};

export default Home;
