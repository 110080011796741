import styled from "styled-components";

const StyledHeader = styled.header`
  position: relative;
  height: 675px;
  max-width: 1600px;
  margin: 40px auto;

  div {
    position: absolute;
    width: 35%;
    height: 50%;
    /* border: solid ${(props) => props.theme.colors.primary} 10px; */
    img {
      width: 100%;
      height: 100%;
    }
  }

  .img1 {
    z-index: 1;
    top: 25px;
    left: 17%;
  }

  .img2 {
    z-index: 2;
    top: 75px;
    right: 17%;
  }
  .img3 {
    z-index: 3;
    top: 275px;
    left: 20%;
  }
  .img4 {
    z-index: 4;
    top: 325px;
    right: 15%;
  }

  @media all and (max-width: 1000px) {
    height: 500px;
    div {
      width: 40vw;
      height: 25vw;
    }
    .img1 {
      z-index: 1;
      top: 50px;
      left: 5%;
    }

    .img2 {
      z-index: 2;
      top: 75px;
      right: 5%;
    }
    .img3 {
      z-index: 3;
      top: 275px;
      left: 15%;
    }
    .img4 {
      z-index: 4;
      top: 250px;
      right: 20%;
    }
  }
  @media all and (max-width: 700px) {
    height: 400px;
    div {
      width: 42.5vw;
      height: 25vw;
    }
    .img1 {
      z-index: 1;
      top: 50px;
      left: 5%;
    }

    .img2 {
      z-index: 2;
      top: 75px;
      right: 5%;
    }
    .img3 {
      z-index: 3;
      top: 225px;
      left: 10%;
    }
    .img4 {
      z-index: 4;
      top: 200px;
      right: 15%;
    }
  }

  @media all and (max-width: 415px) {
    margin-bottom: 100px;
    div {
      width: 60vw;
      height: 40vw;
    }
    .img1 {
      z-index: 1;
      top: 50px;
      left: 0;
    }

    .img2 {
      z-index: 2;
      top: 75px;
      right: 0;
    }
    .img3 {
      z-index: 3;
      top: 225px;
      left: 2.5%;
    }
    .img4 {
      z-index: 4;
      top: 200px;
      right: 5%;
    }
  }
  @media all and (max-width: 415px) {
    height: 350px;
  }
`;

export { StyledHeader };
