import { ThemeProvider } from "styled-components";
import { Route, Routes } from "react-router-dom";
import { GlobalStyles } from "./Styles/Global";
import { theme } from "./Styles/themes/MainThemes";
import Home from "./Home";
import "./Styles/font.css";
import Gdpr from "./Gdpr";
import { useEffect } from "react";



  const App: React.FC = () => {
    useEffect(() => {
      // Fetch the canonical URL dynamically or set it based on your logic
      const currentURL = window.location.href;
      const link = document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
      if (link) {
        link.href = currentURL;
      }
    }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/gdpr" element={<Gdpr />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
