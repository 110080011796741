import styled from "styled-components";

const StyledPromo = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 100px 200px;
  margin: 100px 0;
  border-radius: 20px;

  p {
    padding: 0 25px;
    margin: 0 auto;
    text-align: center;
    color: "#222";
    line-height: 1.75;
    font-size: 24px;
    font-style: italic;
  }
  @media all and (max-width: 1200px) {
    padding: 100px 0;
    p {
      font-size: 20px;
    }
  }

  @media all and (max-width: 450px) {
    padding: 100px 0;
    p {
      font-size: 18px;
    }
  }
`;

export { StyledPromo };
