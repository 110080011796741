import styled from "styled-components";

const StyledSection = styled.section`
  padding: 50px 0;
  h2 {
    width: 70vw;
    margin: 0 auto;
    text-align: center;
    border-bottom: solid 3px black;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 3vw;
  }

  p {
    font-size: 18px;
    width: 70vw;
    line-height: 1.5;
    margin: 0 auto;
    text-align: center;
  }
  @media all and (max-width: 415px) {
    h2 {
      width: 95vw;
      font-size: 7vw;
    }
    p {
      font-size: 16px;
      width: 95vw;
    }
  }
`;

export { StyledSection };
