import styled from "styled-components";

const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 100px 0;
  position: relative;
  .text {
    margin: 0 auto;
    max-width: 1600px;
    text-align: center;
    color: "#222";
    line-height: 1.75;
    font-size: 24px;
    font-style: italic;
  }
  .gdpr {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  @media all and (max-width: 1200px) {
    .text {
      font-size: 20px;
    }
  }
  @media all and (max-width: 460px) {
    text {
      font-size: 18px;
    }
  }
`;

export { StyledFooter };
