//styling for theme provider

interface Colors {
  background: string;
  button: string;
  primary: string;
  card: string;
  text_primary: string;
}

interface Breakpoints {
  xs: string;
  s: string;
  m: string;
  l: string;
  xl: string;
}

interface Development {
  underDevelopment: boolean;
  border: string;
}

interface DefaultTheme {
  colors: Colors;
  breakpoints: Breakpoints;
  development: Development;
}

export const theme: DefaultTheme = {
  colors: {
    background: "#F7F7F6",
    button: "#fff", // Dark Green
    primary: "#8fb3c7", // Saddle Brown
    card: "#89c9d4", // Forest Green 5e5e7d
    text_primary: "#222", // Dark Gray
  },

  breakpoints: {
    xs: "320px",
    s: "480px",
    m: "768px",
    l: "992px",
    xl: "1200px",
  },
  development: {
    underDevelopment: false,
    border: "border: solid 2px #fff",
  },
};
