import { StyledFooter } from "../Styles/StyledComponents/StyledFooter";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <StyledFooter>
      <p className="text">
        "Experience the convenience, comfort, and affordability of our apartment rentals in Aalborg. Book your stay with us today and make your group's visit to
        Denmark a memorable one.""
      </p>
      <div className="gdpr">
        {" "}
        <p>© Copyright LWA 2011</p>
        <Link to="/gdpr"> Privacy policy</Link>
      </div>
    </StyledFooter>
  );
};

export default Footer;
