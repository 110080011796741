import styled from "styled-components";

interface CardProps {
  toggleCard?: boolean;
  customHeight?: string;
}

const StyledCard = styled.article<CardProps>`
  background-color: ${(props) => props.theme.colors.card};
  text-align: left;
  position: relative;
  max-width: 450px;
  transition: max-height ease-in-out 300ms;
  max-height: ${(props) => (props.toggleCard ? "1000px" : "300px")};
  border-radius: 20px;
  box-shadow: 0 0 8px rgba(150, 150, 150);
  margin-bottom: 10px;
  overflow: hidden;
  .icon {
    margin: 25px auto;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: ${(props) => props.theme.colors.text_primary};
      font-size: 60px;
      color: white;
    }
  }
  h2 {
    color: ${(props) => props.theme.colors.text_primary};
    margin: 0 auto;
    text-align: center;
    font-weight: 500;
    padding: 0 30px;
  }

  p {
    color: ${(props) => props.theme.colors.text_primary};
    height: 0%;
    font-size: 18px;
    padding: 0 7%;
    margin: 25px auto;
    margin-bottom: 100px;
    transition: opacity ease-in-out 300ms;
    opacity: ${(props) => (props.toggleCard ? "1" : "0")};
  }
  .dashed_line {
    transition: width ease-in-out 300ms;
    width: ${(props) => (props.toggleCard ? "85%" : "0px")};
    margin: 25px auto;
    border-bottom: dashed black 2px;
  }
  .card_btn {
    background-color: ${(props) => props.theme.colors.button};
    padding: 10px 30px;
    border: none;
    font-size: 18px;
    width: 200px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border-radius: 15px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.text_primary};
  }

  @media all and (max-width: 1000px) {
    width: 80vw;
    max-height: ${(props) => (props.toggleCard ? "1000px" : "350px")};

    p {
      font-size: 18px;
    }
  }

  @media all and (max-width: 415px) {
    width: 90vw;
    transition: max-height ease-in-out 300ms;
    max-height: ${(props) => (props.toggleCard ? "1000px" : "350px")};
    overflow: hidden;

    .icon {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h2 {
    }

    p {
      font-size: 17px;
      transition: opacity ease-in-out 300ms;
      opacity: ${(props) => (props.toggleCard ? "1" : "0")};
    }
    .dashed_line {
      transition: width ease-in-out 300ms;
      width: ${(props) => (props.toggleCard ? "88%" : "0px")};
      border-bottom: dashed black 2px;
    }
    .card_btn {
      padding: 10px 30px;
      border: none;
      font-size: 18px;
      width: 200px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      border-radius: 15px;
      user-select: none;
    }
  }
`;

export { StyledCard };
