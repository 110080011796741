import { createGlobalStyle } from "styled-components";

interface Global {
  theme: {
    colors: {
      background: string;
      tetiear: string;
      primary: string;
      secondary: string;
    };
    breakpoints: {
      xs: string;
      s: string;
      m: string;
      l: string;
      xl: string;
    };
    development: {
      underDevelopment: boolean;
      border: string;
    };
  };
  rows?: string;
}

export const GlobalStyles = createGlobalStyle<Global>`



* {
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat';
    -webkit-tap-highlight-color: transparent;
    
}
html, body {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.background};

}
#root {
  margin: 0 auto;
   width: 100%;
     background-color: ${(props) => props.theme.colors.background};

}
`;
