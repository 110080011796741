import styled from "styled-components";

const StyledForm = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 120px;
  margin-top: 50px;
  margin-bottom: 100px;
  h2 {
    margin-bottom: 30px;
  }
  form {
    position: relative;
    width: 350px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 30px;
    div {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
    }
    input {
      width: 100%;
      height: 30px;
      padding-left: 10px;
      border: none;
      border-radius: 5px;
    }
    input[type="checkbox"] {
      align-self: flex-start;
      width: 30px;
      border: solid 1px red;
    }
    textarea {
      width: 100%;
      height: 200px;
      resize: none;
      padding: 10px;
      border: none;
      border-radius: 5px;
    }
    label {
      padding: 0;
      text-align: left;
      font-size: 13px;
    }

    button {
      cursor: pointer;
      border-radius: 15px;
      /* box-shadow: 0 0 5px rgba(156, 123, 92); */
      background-color: ${(props) => props.theme.colors.card};
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1.2px;
      padding: 10px 30px;
      border: none;
      font-size: 18px;
      color: white;
    }
  }
  .whatsApp {
    svg {
      color: #1ad03f;
    }
  }
  ul {
  }
  .service {
    font-size: 15px;
  }
  li {
    margin-bottom: 10px;
    font-size: 18px;
    display: flex;
    gap: 20px;
  }
  .contact_info {
    margin-top: 35px;
  }
  @media all and (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export { StyledForm };
