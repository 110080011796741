import styled from "styled-components";

const StyledMain = styled.main`
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  .card_container {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 50px;
  }

  .city {
    text-align: center;
    font-size: 4.5rem;
    font-weight: 500;
  }

  .logo_container {
    width: 100%;
    margin: 100px 0;
    .logo {
      margin: 0 auto;
      width: 30%;
    }
    h1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .h1-1 {
        font-size: clamp(1rem, 8vw , 10rem);
       font-family: 'Source Sans Pro', sans-serif;
       color: #000020;
       letter-spacing: 10px;
      }
      .h1-2 {
        font-size: clamp(0.7rem, 2vw , 4rem);
        letter-spacing: 5px;
        color: #0e5861;
        font-family: 'Playfair Display', serif;
      }
    }
  }
  @media all and (max-width: 1000px) {
   
    .city {
      text-align: center;
      font-size: 8vw;
      font-weight: 500;
    }
    .card_container {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }

  @media all and (max-width: 500px) {

    .logo_container {
    width: 100%;
    margin: 50px 0;
    .logo {
      margin: 0 auto;
      width: 30%;
    }
    h1 {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .h1-1 {
        font-size: clamp(1rem, 8vw , 10rem);
       font-family: 'Source Sans Pro', sans-serif;
       color: #000020;
       letter-spacing: 10px;
      }
      .h1-2 {
        font-size: clamp(0.7rem, 2vw , 4rem);
        letter-spacing: 5px;
        color: #0e5861;
        font-family: 'Playfair Display', serif;
      }
    }
  }
  }

  @media all and (max-width: 415px) {
 
    .card_container {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }
`;

export { StyledMain };
